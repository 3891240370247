<!-- 3D可视化 -->
<template>
	<div class="generatechart" data-aos="slide-down" data-aos-delay="0">
		<div v-if="showData.T_v3d==''">
			<div class="no-data">  
                <div class="no-data-item">  
                    <div class="no-data-text" style="color: #2F6BCF;">哎呀！暂无数据可展示哦</div>
                </div>
            </div>
		</div>
		<div v-else style="width: 100%;height: 100%;display: flex;">
		</div>
	</div>
</template>

<script>
	import {mapGetters} from "vuex"
	export default {
		computed:mapGetters(["showData","showFirm"]),
		data() {
			return {
				token:this.$store.state.userInfo.token
			}
		},
		created() {
			////console.log('luj',this.showData.T_plan,this.token)
			// window.open('https://osscold.baozhida.cn/Tool/DataTool.htm','_blank')
		},
		mounted() {
		}
	}
</script>

<style lang="scss">
	.generatechart{
		height: 100vh;
	}
</style>
